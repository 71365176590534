import Logo from './Logo';
import ContactInfo from './ContactInfo';
import React from 'react';

/** Компонент хедера */
const Header: React.FC<{
  /** блок информации о пользователе */
  userInfo: React.ReactNode;
  /** иинтерфейс интеллеки */
  isIntelleka?: boolean;
}> = ({ userInfo, isIntelleka }) => {
  return (
    <div className="bg-white max-w-[1000px] mx-auto flex justify-between items-center p-[10px] md:p-0 md:min-h-[100px]">
      <Logo isIntelleka={isIntelleka} />
      <ContactInfo isIntelleka={isIntelleka} />
      {userInfo}
    </div>
  );
};

export default Header;
