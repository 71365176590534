import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API } from '../../../api';
import { IMaterial, TCreateMaterial } from '../../../typings/admin';
import { useSortMaterials } from '../../../ui/admin/store/useMaterials';
import { getError } from '../../../utils/api';
import { checkIsFileEmpty } from '../../../utils/link';
import { queryClient } from '../../../hooks/queryClient';
import { getMaterialsKey } from '.';

export function useAddMaterial(courseId: string, materialsCount: number) {
  const { setError, setAddMaterialOpen } = useSortMaterials();
  return useMutation<IMaterial, unknown, TCreateMaterial>(
    (data) => {
      return API.admin.material.create({
        ...data,
        course: Number(courseId),
        rank: materialsCount,
        video: checkIsFileEmpty(data.video),
        pdf: checkIsFileEmpty(data.pdf),
      });
    },
    {
      onSuccess: (material) => {
        queryClient.setQueryData(getMaterialsKey({ course: courseId }), () => {
          const oldData = queryClient.getQueryData<IMaterial[]>(
            getMaterialsKey({ course: courseId }),
          );
          if (oldData) {
            return oldData.concat(material);
          }
        });
        setAddMaterialOpen(false);
        window.scrollTo({ top: 9999999 });
      },
      onError: (e) => setError(getError(e as AxiosError)),
    },
  );
}
