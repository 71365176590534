import { useQuery } from 'react-query';
import { API } from '../../../api';
import { ICourse, ICoursesParams } from '../../../typings/admin';
import { key } from '.';
import { getKeyCourse } from './keys';

export const useGetCourses = (params?: Partial<ICoursesParams>) =>
  useQuery(key, () => API.admin.course.all(params));

export const useGetCourse = (course?: ICourse) =>
  useQuery(getKeyCourse(course?.id), () => course && API.admin.course.byId(course?.id), {
    enabled: !!course?.id,
    retry: false,
  });

export const useGetCourseById = (courseId: string) =>
  useQuery(getKeyCourse(courseId), () => API.admin.course.byId(courseId), {
    retry: false,
  });
