import { useMatch, useNavigate } from '@tanstack/react-location';
import { useMutation } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { API } from '../../../api';
import { queryClient } from '../../../hooks/queryClient';
import { ICourse } from '../../../typings/admin';
import { key } from '.';

export const useRemoveCourse = (onClose: () => void, id?: number) => {
  const { params } = useMatch();
  const navigate = useNavigate();
  // если в адресной строке есть айди курса, то функция срабатывает со страницы курса
  // и после удаления его нужно перенаправить на общую страницу курсов
  const isNeedRedirect = params.id;
  const { addToast } = useToasts();

  return useMutation<ICourse, unknown, number | string>((data) => API.admin.course.remove(data), {
    onSuccess: () => {
      onClose();
      queryClient.setQueryData(key, () => {
        const oldData = queryClient.getQueryData<ICourse[]>(key);
        if (oldData) {
          return oldData.filter((el) => el.id !== id);
        }
      });
      isNeedRedirect && navigate({ to: '/' });
      addToast('Курс успешно удален', { appearance: 'success' });
    },
    onError: () => {
      addToast('При удалении курса произошла ошибка, попробуйте еще раз', {
        appearance: 'error',
      });
    },
  });
};
