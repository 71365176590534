/**
 * @module time
 * @category общие утилиты
 * @description утилиты по работе с временем
 */

import moment from 'moment';

import { russianEndings } from './string';

/**
 * функция преобразует дату в секунды
 * @param date дата/время
 * @returns секунды
 */
export const stringDateToSeconds = (date: string | number | Date) => {
  return Math.round(new Date(date).getTime() / 1000);
};

/**
 * функция преобразует локальное время в секунды
 * @param time дата/время
 * @returns cекунды
 */
export const localDateTimeToSeconds = (time: string | number | Date) =>
  new Date(time).getTime() / 1000;

/**
 * функция преобразует локальное время в timestamp
 * @param time дата/время
 * @returns timestamp
 */
export const localDateTimeToTimestamp = (time: string | number | Date) => new Date(time).getTime();

/**
 * функция преобразует секунды в выбранный формат с помощью библиотеки [moment](https://github.com/moment/moment/)
 * @param timestamp timestamp в секундах
 * @param format формат времени
 * @returns строка в указаном формате времени
 */
export const convertSecondsToFormat = (timestamp: number, format?: string) =>
  moment(timestamp * 1000).format(format);

/**
 * функция преобразует timestamp в выбранный формат с помощью библиотеки [moment](https://github.com/moment/moment/)
 * @param timestamp timestamp
 * @param format формат времени
 * @returns строка в указаном формате времени
 */
export const convertTimestampToDate = (timestamp: moment.MomentInput, format: string | undefined) =>
  moment(timestamp).format(format);

/**
 * функция преобразует локальное время в выбранный формат с помощью библиотеки [moment](https://github.com/moment/moment/)
 * @param time дата/время
 * @param format формат
 * @returns строка в указаном формате времени
 */
export const convertLocalTimeToFormat = (
  time: string | number | Date,
  format: string | undefined,
) => moment(new Date(time).getTime()).format(format);

/** строка времени "через 30 дней" в формате ГГГГ-ММ-ДД */
export const in30Days = convertLocalTimeToFormat(Date.now() + 86400 * 1000 * 30, 'YYYY-MM-DD');

/**
 * функция конвертирует секунды в часы и минуты
 * @param seconds секунды
 * @param hasComa наличие запятой в конце строки результата
 * @returns строка в формате "ХХ часов ХХ минут ХХ секунд"
 */
export const toHoursAndMinutes = (seconds: number, hasComa?: boolean) => {
  const secondsLeft = russianEndings(Math.round(seconds), [' сек. ', 'сек. ', 'сек. ']);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  const hoursRender = hours >= 1 ? hours + russianEndings(hours, [' час', ' часа', ' часов']) : '';
  const minutesRender =
    minutesLeft >= 1
      ? minutesLeft + russianEndings(minutesLeft, [' минуту', ' минуты', ' минут'])
      : '';
  return seconds < 60
    ? Math.floor(seconds) + ' ' + secondsLeft
    : hasComa
    ? `${hoursRender}${minutesRender && hoursRender ? ' ' : ''}${minutesRender},`
    : `${hoursRender}${minutesRender && hoursRender ? ' ' : ''}${minutesRender}`;
};

/**
 * функция превращает время в формате 00:00:00 в секунды
 * @param string строка в формате 00:00:00
 * @returns секунды
 */
export const stringToSeconds = (string?: string) => {
  const colons = string ? string.split(':') : [];
  return +colons[0] * 60 * 60 + +colons[1] * 60 + +colons[2];
};

/**
 * функция преобразует время из формата 00:00:00 в формат ХХ часов ХХ минут ХХ секунд
 * @param stringTime время в формате 00:00:00
 * @returns строка времени в формате ХХ часов ХХ минут ХХ секунд
 */
export const formattedTime = (stringTime?: string) => {
  const seconds = stringToSeconds(stringTime);
  return toHoursAndMinutes(seconds);
};

/**
 * функция возвращает возвращает таймстемп времени, после вычета указанных в параметрах дней
 * @param days кол-во дней
 * @returns возвращает таймстемп времени, после вычета указанных дней
 */
export const getFromNow = (days: number) => {
  const today = new Date();
  return today.setDate(today.getDate() - days);
};

/**
 * Функция отнимает один день от значения даты для нормализации строки времени
 * @param value строка времени
 * @param divider разделитель
 * @returns Возвращает нормализованную дату
 */
export const normalizeDate = (value: string, divider: string) => {
  if (!value) {
    return new Date();
  }

  const [year, month, day] = value.split(divider);

  return new Date(Number(year), Number(month) - 1, Number(day));
};

/**
 * Функция преобразует строку в дату
 * @param date строка времени
 * @returns Возвращает дату в формате "7 дек. 2021 г."
 */
export const renderDate = (date: string) => {
  return new Date(Date.parse(date)).toLocaleDateString('ru', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

/**
 * Функция преобразует строку в дату
 * @param date строка времени
 * @returns Возвращает дату в формате "07.12.21"
 */
export const renderShortDate = (date: string) => {
  return new Date(Date.parse(date)).toLocaleDateString('ru', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  });
};

/**
 * Функция конвертирует секунды в дни
 * @param seconds секунды
 * @returns кол-во дней
 */
export const secondsToDays = (seconds: number) => Math.floor(seconds / (3600 * 24));

/**
 * Функция преобразует секунды в строку времени
 * @param seconds секунды
 * @returns строка времени в формате "XX:XX:XX"
 */
export const secondsToTime = (seconds: number) => {
  return new Date(seconds * 1000).toUTCString().match(/(\d\d:\d\d:\d\d)/)?.[0];
};

/**
 * функция преобразует число Х в формат 0X
 * @param n число
 * @returns преобразованное число
 */
function prepZero(n: number) {
  if (n < 10) return '0' + n;
  return n;
}

/**
 * функция преобразовывает секунды в дни/часы/минуты/секунды
 * @param seconds секунды
 * @returns строка времени в формате "XX:XX:XX:XX"
 */
export function secondsToDhms(seconds: number) {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  return `${prepZero(d)}:${prepZero(h)}:${prepZero(m)}:${prepZero(s)}`;
}
