import { BookmarkIcon } from '@heroicons/react/solid';
import int_logo from '../img/logo_inteleca.svg';
import { IoSchoolOutline } from 'react-icons/io5';
import pr_logo from '../img/logo_promr.svg';
import SvgBook from '../react-svg/SvgBook';

export interface ILogoProps {
  /** Интерфейс интелеки */
  isIntelleka?: boolean;
}

/** Компонент логотипа */
const Logo: React.FC<ILogoProps> = ({ isIntelleka = false }) => {
  const logo = isIntelleka ? int_logo : pr_logo;

  // return <img src={logo} alt="logo" className="block w-[120px] md:w-[180px]" />;
  return (
    <div className="text-[rgb(255,96,65)]">
      <div className="flex items-center gap-3">
        <div>
          <IoSchoolOutline size={40} />
        </div>
        <div className="text-lg">Online School</div>
      </div>
    </div>
  );
};

export default Logo;
