import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IMaterial } from '../../../typings/admin';
import { Modal } from '@mui/material';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import { FaFileVideo } from 'react-icons/fa';
import RadioToggle from '../../../shared/RadioToggle';
import { checkIsIntelleka } from '../../../utils/link';
import { useSortMaterials } from '../store/useMaterials';
import { Alert } from '@mui/material';
import { ThemeButton } from '../../../shared/Button';
import ThemeInput from '../../../shared/ThemeInput';
import ThemeTextarea from '../../../shared/ThemeTextarea';

const AdminMaterialForm: React.FC<{
  open: boolean;
  onClose(): void;
  submit: (data: IMaterial) => void;
  initial?: IMaterial;
  loading?: boolean;
}> = (props) => {
  const { register, handleSubmit, reset, setValue, control } = useForm<IMaterial>();
  const { error, setError } = useSortMaterials();

  useEffect(() => {
    if (props.open) {
      reset();
      setPdfName('');
      setVideoName('');
    }
    if (props.initial) {
      setValue('title', props.initial.title);
      setValue('text', props.initial.text);
      setValue('parent', props.initial.parent);
      setValue('pdf', props.initial.pdf);
      setValue('video', props.initial.video);
      setValue('video_link', props.initial.video_link);
      setValue('is_download', props.initial.is_download);
      setValue('id', props.initial.id);
    }
  }, [props.open, setValue, props.initial, reset]);

  const resetValues = () => {
    if (props.initial) {
      setValue('title', props.initial.title);
      setValue('text', props.initial.text);
      setValue('parent', props.initial.parent);
      setValue('pdf', props.initial.pdf);
      setValue('video', props.initial.video);
      setValue('video_link', props.initial.video_link);
      setValue('is_download', props.initial.is_download);
    } else {
      reset();
      setPdfName('');
      setVideoName('');
    }
  };

  // const materialsSelect = materials
  //   ?.filter((el) => el.id !== props.initial?.id)
  //   ?.map((a) => ({
  //     value: a.id,
  //     label: a.title,
  //   }));

  const [pdfName, setPdfName] = useState('');
  const [videoName, setVideoName] = useState('');

  const isIntelleka = checkIsIntelleka();

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div className="outline-none rounded max-w-[800px] m-auto mt-0 sm:mt-[1.75rem] text-black-default cursor-default text-left p-3 text-[17px] bg-white">
        <form onSubmit={handleSubmit(props.submit)}>
          <label className="block mb-5">
            <div className="mb-1">Название</div>
            <Controller
              control={control}
              render={({ field }) => {
                return (
                  <ThemeInput
                    {...field}
                    className="w-full"
                    autoFocus
                    isIntelleka={isIntelleka}
                    value={field.value || ''}
                  />
                );
              }}
              name="title"
            />
          </label>
          <label className="block mb-5">
            <div className="mb-1">Описание</div>
            <Controller
              control={control}
              render={({ field }) => (
                <ThemeTextarea
                  {...field}
                  className="w-full"
                  isIntelleka={isIntelleka}
                  value={field.value || ''}
                />
              )}
              name="text"
            />
          </label>
          {typeof props.initial?.pdf === 'string' && (
            <a
              className="flex mb-4 items-center w-fit hover:opacity-75"
              href={props.initial.pdf}
              target="_blank"
              rel="noreferrer"
            >
              <BsFillFileEarmarkPdfFill />
              <div className="ml-1">{props.initial.title}</div>
            </a>
          )}
          {typeof props.initial?.video === 'string' && (
            <a
              className="flex mb-4 items-center w-fit hover:opacity-75"
              href={props.initial.video}
              target="_blank"
              rel="noreferrer"
            >
              <FaFileVideo />
              <div className="ml-1">{props.initial.title}</div>
            </a>
          )}
          {(!props.initial || props.initial.pdf) && (
            <label className="block mb-5 w-fit cursor-pointer hover:opacity-75">
              <div className="mb-1 flex items-center">
                <div>{typeof props.initial?.pdf === 'string' ? 'Изменить' : 'Загрузить'} файл</div>
                <AiOutlineCloudUpload className="text-lg mx-2" />
                {pdfName}
              </div>
              <input
                type="file"
                accept="application/pdf"
                {...register('pdf')}
                className="hidden"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target?.files?.[0]) {
                    setPdfName(e.target?.files?.[0].name);
                    setValue('pdf', e.target.files[0]);
                  }
                }}
              />
            </label>
          )}
          {(!props.initial || props.initial.video) && (
            <label className="block mb-5 w-fit cursor-pointer hover:opacity-75">
              <div className="mb-1 flex items-center">
                <div>
                  {typeof props.initial?.video === 'string' ? 'Изменить' : 'Загрузить'} видео
                </div>
                <AiOutlineCloudUpload className="text-lg mx-2" />
                {videoName}
              </div>
              <input
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                {...register('video')}
                className="hidden"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target?.files?.[0]) {
                    setVideoName(e.target?.files?.[0].name);
                    setValue('video', e.target.files[0]);
                  }
                }}
              />
            </label>
          )}
          {(!props.initial || props.initial.video_link) && (
            <label className="block mb-5">
              <div className="mb-1">YouTube</div>
              <Controller
                control={control}
                render={({ field }) => (
                  <ThemeInput
                    {...field}
                    className="w-full"
                    isIntelleka={isIntelleka}
                    value={field.value || ''}
                  />
                )}
                name="video_link"
              />
            </label>
          )}
          <label className="block w-fit mb-5">
            <Controller
              control={control}
              render={({ field: { name, value } }) => {
                return (
                  <RadioToggle
                    label="Разрешить скачивать"
                    checked={value}
                    onChange={() => setValue(name, !value)}
                    isIntelleka={isIntelleka}
                  />
                );
              }}
              name="is_download"
            />
          </label>
          <div className="flex justify-between mt-3">
            <div>
              <ThemeButton
                onClick={props.onClose}
                className="mr-1"
                isSecondary
                isIntelleka={isIntelleka}
                type="button"
              >
                Отменить
              </ThemeButton>
              <ThemeButton isIntelleka={isIntelleka} type="submit" disabled={props.loading}>
                {props.loading ? 'Сохранение...' : 'Сохранить'}
              </ThemeButton>
            </div>
            <ThemeButton isIntelleka={isIntelleka} type="button" onClick={resetValues}>
              Сбросить
            </ThemeButton>
          </div>
        </form>
        {props.open && !!error && (
          <Alert color="error" className="notification" onClose={() => setError('')}>
            {error}
          </Alert>
        )}
      </div>
    </Modal>
  );
};

export default AdminMaterialForm;
