import { Tooltip } from '@mui/material';
import Notice from './Notice';
import { IProps } from './typings';

/** Компонент рендера времени проведенного на образовательном портале */
const TimeCosts: React.FC<IProps> = (props) => (
  <div
    className={`flex items-center ${
      props.connectionStatus === 'Connecting' || props.connectionStatus === 'Closed'
        ? 'text-gray-400'
        : 'text-inherit'
    }`}
  >
    <Tooltip arrow title={<div className="tooltip-summary">{props.tooltipText}</div>}>
      <div>{props.value}</div>
    </Tooltip>
    {/* {props.connectionStatus === 'Closed' && <Notice />} */}
  </div>
);

export default TimeCosts;
