import React, { useEffect } from 'react';
import { useCourseMaterials } from '../store/useCourseMaterials';
import { changeHostMaterial } from '../../../utils/link';
import { useMutation, useQuery } from 'react-query';
import { ICreateMaterialPassing, IUpdateMaterialPassing } from '../../../typings/client';
import { queryClient } from '../../../hooks/queryClient';
import { getCurrentUser } from '../../../api/common';
import ClientPdfReader from './ClientPdfReader';
import { useMaterial } from '../store/useMaterial';
import ClientMaterialVideo from './ClientMaterialVideo';
import ClientMaterialYoutube from './ClientMaterialYoutube';
import { API } from '../../../api';
import smallPdf from '../../../mocks/pdf/small.pdf';
import mediumPdf from '../../../mocks/pdf/medium.pdf';
import { isDev } from '../../../constants';

const ClientMaterialRender: React.FC<{ materialId: number }> = ({ materialId }) => {
  const { materials } = useCourseMaterials();
  const file = materials[materialId] || '';
  const { data: user } = useQuery('user', getCurrentUser);
  const passingKey = ['passing', materialId];
  const { data: passings } = useQuery(passingKey, () =>
    API.client.material_passing.getMaterialPassings({ material: materialId }),
  );
  const { mutate: createPassing } = useMutation(
    (p: ICreateMaterialPassing) => API.client.material_passing.createMaterialPassing(p),
    {
      onSuccess: () => queryClient.refetchQueries(passingKey),
    },
  );

  const { mutate: changePassing } = useMutation(
    (p: IUpdateMaterialPassing) => API.client.material_passing.updateMaterialPassing(p),
    {
      onSuccess: (res) => {
        queryClient.refetchQueries(passingKey);
        res.status === 2 && queryClient.refetchQueries('all-passings');
      },
    },
  );

  const passing = passings?.find((p) => p.material === materialId);

  const passingSatus = passing?.status;

  useEffect(() => {
    if (!passingSatus && user) {
      createPassing({ material: materialId, status: 1, user: user.id });
    }
  }, [passingSatus, createPassing, materialId, passings, user]);

  const pdf = changeHostMaterial(file.pdf);
  const video = changeHostMaterial(file.video);
  const video_link = file.video_link;

  const { setNext } = useMaterial();

  /** если материал не имеет файлов, переходить на следующий */
  useEffect(() => {
    file && !pdf && !video && !video_link && setNext();
  }, [file, pdf, setNext, video, video_link]);

  const materialWatched = () =>
    passing &&
    user &&
    passing.status === 1 &&
    changePassing({ id: passing.id, status: 2, material: materialId, user: user?.id });

  return (
    <div className="relative overflow-hidden">
      {pdf && (
        <ClientPdfReader
          pdf={isDev ? (materialId % 2 ? smallPdf : mediumPdf) : pdf}
          onRead={materialWatched}
        />
      )}
      {video && <ClientMaterialVideo link={video} onEnded={materialWatched} />}
      {video_link && <ClientMaterialYoutube link={video_link} onEnded={materialWatched} />}
    </div>
  );
};

export default ClientMaterialRender;
