import 'moment/locale/ru';
import moment from 'moment';
import Message from './Message';
import ChatForm from './ChatForm';
import Scrollbars from 'react-custom-scrollbars';

import MessageDate from './MessageDate';
import { Ref } from 'react';
import { ICurrentUser } from '../typings/common';
import { IChatMessage } from '../ui/common/containers/ChatContainer';
import Spinner from './Spinner';
import { checkIsIntelleka } from '../utils/link';

export const ChatPreloader: React.FC<{ text: string }> = ({ text }) => {
  const isIntelleka = checkIsIntelleka();

  return (
    <div
      className={`p-7 ${
        isIntelleka ? 'text-active_i' : 'text-active_p'
      } flex items-center justify-center`}
    >
      <div className="">{text}</div>
      <Spinner className={`w-[17px] ${isIntelleka ? 'fill-active_i' : 'fill-active_p'} ml-2`} />
    </div>
  );
};

/** Компонент чата */
const Chat: React.FC<{
  /** ref компонента для регулирования скрола */
  scrollBarRef?: Ref<Scrollbars>;
  /** история сообщений */
  messageHistory: IChatMessage[];
  /** функция отправки сообщения на сервер */
  sendJsonMessage(data: { command: string; message: string }): void;
  /** значение индикатора отправки */
  sending: boolean;
  /** колбек для изменения индикатора отправки */
  setSending(sending: boolean): void;
  /** данные текущего пользователя (от имени которого открыт чат) */
  currentUser?: ICurrentUser;
  /** индикатор подключения к чату */
  WS_CONNECTING: boolean;
  /** подключено к чату */
  WS_CONNECTED: boolean;
  /** подключение прервано */
  WS_CLOSED: boolean;
  /** интерфейс интелеки */
  isIntelleka: boolean;
}> = ({
  scrollBarRef = null,
  messageHistory = [],
  sendJsonMessage = () => null,
  sending = false,
  setSending = () => null,
  currentUser,
  WS_CONNECTING = false,
  WS_CONNECTED = true,
  WS_CLOSED = false,
  isIntelleka,
}) => {
  return (
    <>
      <Scrollbars
        ref={scrollBarRef}
        style={{
          /** высота высчитывается иcходя из количества пунктов меню в сайдбаре, минус 1 пикс. бордера */
          height: currentUser?.user_status === 3 ? window.innerHeight - 240 : 4 * 83 - 1,
        }}
      >
        <div className="p-6">
          {messageHistory?.map((message: IChatMessage, index, all) => {
            const prevMessage = all[index - 1];
            const prevDate = moment(prevMessage?.created).format('dddd, D MMMM');
            const currentDate = moment(message?.created).format('dddd, D MMMM');
            return (
              <div key={index}>
                {prevDate !== currentDate && <MessageDate date={currentDate} />}
                <Message data={message} currentUser={currentUser} isIntelleka={isIntelleka} />
              </div>
            );
          })}
        </div>
      </Scrollbars>
      {!WS_CONNECTING && WS_CONNECTED ? (
        <ChatForm
          isIntelleka={isIntelleka}
          sending={sending}
          onSend={(message) => {
            if (!message) return console.warn('message is empty');
            setSending(true);
            sendJsonMessage({
              command: 'send',
              message,
            });
          }}
          disabled={!WS_CONNECTED}
        />
      ) : WS_CLOSED ? (
        <div className="text-red-500 text-center p-7 border border-dashed">Ошибка подключения</div>
      ) : (
        <div className="border border-dashed">
          <ChatPreloader text="Подключение к чату..." />
        </div>
      )}
    </>
  );
};

export default Chat;
