import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API } from '../../../api';
import { queryClient } from '../../../hooks/queryClient';
import { ICourse } from '../../../typings/admin';
import { useCoursesErrors } from '../../../ui/admin/store/useCourses';
import { getError } from '../../../utils/api';
import { cleanObject } from '../../../utils/object';
import { key } from '.';

export const useUpdateCourse = (onClose: () => void, course?: ICourse) => {
  const { setError } = useCoursesErrors();

  return useMutation<ICourse, unknown, ICourse>(
    (data) => API.admin.course.update({ ...cleanObject(course), ...data }),
    {
      onSuccess: (data) => {
        onClose();
        queryClient.refetchQueries('course');
        queryClient.setQueryData(key, () => {
          const oldData = queryClient.getQueryData<ICourse[]>(key);
          if (oldData) {
            return [data, ...oldData.filter((el) => el.id !== data.id)];
          }
        });
      },
      onError: (e) => setError(getError(e as AxiosError)),
    },
  );
};
