/** Компонент заголовка курса */
const CourseHeader: React.FC<{ title?: string; isIntelleka?: boolean }> = ({
  title = '...',
  isIntelleka = false,
}) => {
  return (
    <div
      className={`flex items-center ${
        isIntelleka ? 'gradient_i' : 'gradient_p'
      } justify-center min-h-[185px] pb-[50px]`}
    >
      <div className="py-12 text-white max-w-[900px] text-center text-lg lg:text-huge leading-[38px]">
        {title}
      </div>
    </div>
  );
};

export default CourseHeader;
