import BaseTemplate from '../../common/containers/BaseTemplate';
import ClientCourseSummary from '../containers/ClientCourseSummary';
import CourseHeader from '../../../shared/CourseHeader';

import { useQuery } from 'react-query';
import { API } from '../../../api';
import { useEffect } from 'react';
import { useCourse } from '../store/useCourse';
import { clearStringHtml, maxStringLength } from '../../../utils/string';
import ClientMaterialView from '../containers/ClientMaterialView';
import ClientTestView from '../containers/ClientTestView';
import { useCourseTasks } from '../store/useCourseTasks';
import { keyBy } from 'lodash';
import { useTest } from '../store/useTest';
import ClientSidebarMenu from '../containers/ClientSidebarMenu';
import ClientCoursesMenu from '../containers/ClientCoursesMenu';
import { useTests } from '../store/useTests';
import { stringToSeconds } from '../../../utils/time';
import { getServerTime } from '../../../api/common';
import ClientStudingProgress from '../containers/ClientStudingProgress';
import { useCookie } from 'react-use';
import AsideTemplate from '../../common/templates/AsideTemplate';
import { checkIsIntelleka } from '../../../utils/link';
import { useGetTasks } from '../../../cache/client/task';
import { useMaterial } from '../store/useMaterial';
import { useGetMaterials } from '../../../cache/client/material';
import { IMaterial } from '../../../typings/client';

const ClientTemplate: React.FC = ({ children }) => {
  const { setUser } = useTest();
  const { setTaskDuration, tasksDuration, setServerTime } = useTests();

  useQuery('time', getServerTime, {
    onSuccess: (res) => setServerTime(res.server_time),
    staleTime: 999999999,
  });

  const { data: user } = useQuery('user', API.client.user.getCurrentUserClient, {
    onSuccess: (user) => setUser(user),
  });
  const { data: courses } = useQuery('courses', () => API.client.course.getAllCourses());
  const { course, setCourse } = useCourse();
  const { setCourseTasksByMaterial, setCourseTasksById } = useCourseTasks();
  const { data: tasks, refetch: tasksRefetch } = useGetTasks(course, (res) => {
    res.forEach(
      (t) => !tasksDuration[t.id] && setTaskDuration(t.id, stringToSeconds(t.travel_time)),
    );
  });

  const { setMaterials, setMaterialToContinue } = useMaterial();

  const onSuccess = (res: IMaterial[]) => {
    setMaterials(res);
    setMaterialToContinue(res);
  };

  const { refetch: materialsRefetch } = useGetMaterials(course, onSuccess);

  useEffect(() => {
    if (course) {
      tasksRefetch();
      materialsRefetch();
    }
  }, [course, tasksRefetch, materialsRefetch]);

  useEffect(() => {
    const tasksByMaterialId = keyBy(tasks, 'material');
    const tasksById = keyBy(tasks, 'id');
    tasksByMaterialId && setCourseTasksByMaterial(tasksByMaterialId);
    tasksById && setCourseTasksById(tasksById);
  }, [course, tasks, setCourseTasksByMaterial, setCourseTasksById]);

  const course_title = courses?.find((c) => c.id === course)?.title;

  const [course_cookie, updateCookie] = useCookie('course');

  const setStorageCourseId = (courseId: number) => {
    setCourse(courseId);
    updateCookie(String(courseId));
  };

  useEffect(() => {
    if (!!courses?.length) {
      if (course_cookie) {
        const coursesIds = courses.map((c) => c.id);
        if (coursesIds.includes(Number(course_cookie))) {
          setCourse(Number(course_cookie));
        } else {
          setStorageCourseId(courses[0].id);
        }
      } else {
        setStorageCourseId(courses[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses, course_cookie]);

  const isIntelleka = checkIsIntelleka();

  return (
    <BaseTemplate
      user={maxStringLength(user?.first_name || '', 30)}
      description={maxStringLength(user?.company_title || '', 30)}
      userStatus={user?.user_status}
    >
      <CourseHeader title={course_title} isIntelleka={isIntelleka} />
      <div className="bg-gray-ultra_light">
        <div className="h-14">
          <ClientCourseSummary course={course} user={user?.id} />
        </div>

        <AsideTemplate
          className="flex w-full max-w-[1022px] rounded bg-white m-auto z-10 relative min-h-[calc(100vh-432px)]"
          btnClassName="m-4 mt-0"
          sidebar={
            <div className="overflow-hidden">
              <ClientSidebarMenu
                hasDescription={Boolean(
                  clearStringHtml(courses?.find((c) => c.id === course)?.description),
                )}
                hasTasks={!!tasks?.length}
              />
              <ClientCoursesMenu
                setStorageCourseId={setStorageCourseId}
                courses={courses}
                course={course}
              />
            </div>
          }
        >
          <ClientStudingProgress />
          <div>{children}</div>
        </AsideTemplate>
      </div>
      <ClientMaterialView />
      <ClientTestView />
    </BaseTemplate>
  );
};

export default ClientTemplate;
