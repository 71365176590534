import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API } from '../../../api';
import { ITest, TUpdateTask } from '../../../typings/admin';
import { useCourseTests } from '../../../ui/admin/store/useTests';
import { getError } from '../../../utils/api';
import { queryClient } from '../../../hooks/queryClient';
import { getKey } from '.';
import { useToasts } from 'react-toast-notifications';

export function useUpdateTest(
  /** айди курса */
  courseId: string,
) {
  const key = getKey(courseId);
  const { setError, setEditingId } = useCourseTests();
  return useMutation<ITest, unknown, TUpdateTask>((data) => API.admin.test.update(data), {
    onSuccess: () => {
      setEditingId();
      queryClient.refetchQueries(key);
    },
    onError: (e) => {
      const error = getError(e as AxiosError);
      setError(error);
    },
  });
}

export function useChangeTest(onSuccess: () => void) {
  const { addToast } = useToasts();
  return useMutation(API.admin.test.change, {
    onSuccess,
    onError: () => addToast('Не удалось обновить тест', { appearance: 'error' }),
  });
}
