import '../../../css/sortable.css';
import { FaClipboardList, FaPlus, FaTimes, FaEdit } from 'react-icons/fa';
import { Link, useMatch } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import SortableTree from 'react-sortable-tree';
import { IMaterial } from '../../../typings/admin';
import AdminCourseTemplate from '../templates/AdminCourseTemplate';
import { useSortMaterials } from '../store/useMaterials';
import { getStructuredMaterials } from '../../../utils/material';
import React, { useEffect, useState } from 'react';
import ConfirmModal from '../../../shared/ConfirmModal';
import AdminMaterialForm from '../containers/AdminMaterialForm';
import { generateAddMaterialToTestLink, generateCourseTestLink } from '../routes';
import { checkIsIntelleka } from '../../../utils/link';
import { getMaterialsKey, useChangeMaterial } from '../../../cache/admin/material';
import Truncate from '../../../shared/Truncate';
import { Tooltip } from '@mui/material';
import { API } from '../../../api';
import { useRemoveMaterial } from '../../../cache/admin/material/remove';
import { useCurrentUser } from '../../../cache/common/user';

const MaterialBtn: React.FC<{ icon: React.ReactElement; title: string }> = ({ icon, title }) => {
  return (
    <Tooltip title={title} arrow placement="top">
      <div className="mx-1 cursor-pointer">{icon}</div>
    </Tooltip>
  );
};

const MaterialBody: React.FC<{ item: IMaterial }> = ({ item }) => {
  const { checked, toggleChecked, tree, clearChecked, setButtonsFixed } = useSortMaterials();
  const isCurrentChecked = checked.includes(item.id);
  const match = useMatch();
  const courseId = match.params.id;
  const { data: tests } = useQuery(['tests', `course-${courseId}`], () =>
    API.admin.test.all({ material__course: courseId }),
  );

  useEffect(() => {
    clearChecked();
    setButtonsFixed(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const materialTests = tests?.filter((test) => test.material === item.id);

  return (
    <div className="flex justify-between select-none">
      <label className="flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="mr-1"
          checked={isCurrentChecked}
          onChange={() => {
            if (!item.parent) {
              /** Проходим на три уровня вложенности */
              toggleChecked(item.id);
              if (tree) {
                const currentInTree = tree.find((el) => el.id === item.id);
                if (Array.isArray(currentInTree?.children)) {
                  currentInTree?.children.forEach((el) => {
                    toggleChecked(el.id);
                    if (Array.isArray(el.children)) {
                      el.children.forEach((el) => {
                        toggleChecked(el.id);
                        if (Array.isArray(el.children)) {
                          el.children.forEach((el) => {
                            toggleChecked(el.id);
                          });
                        }
                      });
                    }
                  });
                }
              }
            } else {
              toggleChecked(item.id);
            }
          }}
        />
        <Truncate count={100} title={item.title} />
      </label>
      <div className="flex items-center text-[11px] ml-3">
        <Link to={generateAddMaterialToTestLink(courseId, item.id)}>
          <MaterialBtn icon={<FaPlus />} title="Добавить тест" />
        </Link>
        {materialTests?.map((test) => {
          return (
            <Link key={test.id} to={generateCourseTestLink(courseId, test.id)}>
              <MaterialBtn icon={<FaClipboardList />} title={test.title} />
            </Link>
          );
        })}
        <EditMaterial item={item} courseId={courseId} />
        <RemoveMaterial id={item.id} courseId={courseId} />
      </div>
    </div>
  );
};

const EditMaterial: React.FC<{ item: IMaterial; courseId: string }> = ({ item, courseId }) => {
  const { editId, toggleId } = useSortMaterials();
  const open = editId === item.id;
  const toggle = () => toggleId(open ? undefined : item.id);
  const { mutate: changeMaterial, isLoading } = useChangeMaterial(courseId);

  return (
    <div className="mx-1 cursor-pointer">
      <Tooltip title="Редактировать" arrow placement="top">
        <div>
          <FaEdit onClick={toggle} />
        </div>
      </Tooltip>
      <AdminMaterialForm
        onClose={toggle}
        open={open}
        submit={changeMaterial}
        initial={item}
        loading={isLoading}
      />
    </div>
  );
};

const RemoveMaterial: React.FC<{ id: number; courseId: string }> = ({ id, courseId }) => {
  const { removeId, setRemoveId } = useSortMaterials();
  const { mutate: removeMaterial, isLoading } = useRemoveMaterial(courseId);

  const isIntelleka = checkIsIntelleka();

  return (
    <>
      <div className="mx-1 cursor-pointer">
        <Tooltip title="Удалить" arrow placement="top">
          <div>
            <FaTimes onClick={() => setRemoveId(id)} />
          </div>
        </Tooltip>
        <ConfirmModal
          isIntelleka={isIntelleka}
          className="text-[16px] cursor-default"
          confirm={() => removeMaterial(id)}
          isOpen={removeId === id}
          hide={() => setRemoveId()}
          loading={isLoading}
        >
          <div className="text-left">Вы действительно хотите удалить материал?</div>
        </ConfirmModal>
      </div>
    </>
  );
};

/**
 * Функция преобразует массив материалов для сортировочного дерева на странице материалов в админке
 * @param materials массив материалов
 * @returns преобразованный массив для рендера
 */
export const normalizedMaterials = (collapsed: number[], materials?: IMaterial[]) => {
  if (!materials || materials?.length === 0) {
    return [];
  }

  const normalized = materials.map((item) => {
    return {
      ...item,
      expanded: !collapsed.includes(item.id),
      subtitle: <MaterialBody item={item} />,
    };
  });

  return getStructuredMaterials(normalized);
};

const AdminCourseMaterials: React.FC = () => {
  const match = useMatch();
  const courseId = match.params.id;
  const { data: user } = useCurrentUser();

  const {
    loading,
    tree,
    setTree,
    onMoveNode,
    setButtonsFixed,
    isButtonsFixed,
    addMaterialOpen,
    copyMaterialOpen,
    massAddingMaterialOpen,
    allowCollapse,
  } = useSortMaterials();

  const [collapsed, setCollapsed] = useState<number[]>([]);

  const {
    data: materials,
    isFetching,
    isFetched,
  } = useQuery(
    getMaterialsKey({ course: courseId }),
    async () =>
      API.admin.material.all({
        course: courseId,
        ordering: 'rank',
        search: user?.user_status === 0 ? '' : `${new Date().getFullYear()}`,
      }),
    {
      enabled: !!courseId && !!user,
      onSuccess: (materials) => {
        const sorted = normalizedMaterials(collapsed, materials);
        setTree(sorted);
      },
    },
  );

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 600 && window.innerWidth > 991 && !isButtonsFixed) setButtonsFixed(true);
      if (window.scrollY <= 600 && isButtonsFixed && window.innerWidth > 991)
        setButtonsFixed(false);
    };

    document.addEventListener('scroll', onScroll);

    return () => document.removeEventListener('scroll', onScroll);
  }, [isButtonsFixed, setButtonsFixed]);

  return (
    <AdminCourseTemplate
      isLoading={isFetching && !tree.length}
      isEmpty={isFetched && !materials?.length}
      emptyContent="Нет материалов"
      materialsCount={materials?.length || 0}
    >
      <div
        className={`overflow-x-auto overflow-y-hidden ${allowCollapse ? '' : 'collapse-hidden'}`}
      >
        {!!tree.length && (
          <SortableTree
            className={`${loading ? 'opacity-50' : 'opacity-100'} ${
              !addMaterialOpen && !copyMaterialOpen && !massAddingMaterialOpen
                ? 'visible'
                : 'invisible'
            }`}
            treeData={tree}
            onChange={setTree}
            onMoveNode={onMoveNode}
            isVirtualized={false}
            onVisibilityToggle={(data) => {
              const current = data.node.id;
              setCollapsed(
                collapsed.includes(current)
                  ? collapsed.filter((el) => el !== current)
                  : [...collapsed, current],
              );
            }}
          />
        )}
      </div>
    </AdminCourseTemplate>
  );
};

export default AdminCourseMaterials;
