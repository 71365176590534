import { useMatch } from '@tanstack/react-location';
import { useGetCourseById } from '../../../cache/admin/course';
import AdminCourseTemplate from '../templates/AdminCourseTemplate';

const AdminCourseInfo: React.FC = () => {
  const match = useMatch();
  const courseId = match.params.id;
  const { data: course, isFetching, isFetched } = useGetCourseById(courseId);
  const description = course?.description?.replaceAll(' ', '<br />')?.replaceAll('<br>', '');
  const isEmpty = (isFetched && !course?.description) || description === '<p></p>';

  return (
    <AdminCourseTemplate isLoading={isFetching && !course} isEmpty={isEmpty}>
      <div className="px-4 lg:px-0">
        {course?.description && (
          <div dangerouslySetInnerHTML={{ __html: course?.description || '' }} />
        )}
      </div>
    </AdminCourseTemplate>
  );
};

export default AdminCourseInfo;
