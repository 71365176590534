/**
 * @module link
 * @category общие утилиты
 * @description утилиты по работе со ссылками
 */

import Cookies from 'js-cookie';
import { intellekaDomains, isDev } from '../constants';

/**
 * функция проверяет текущий протокол и возвращает true (если https) или false (если http)
 */
export function checkIsSSL(): boolean {
  return document.location.protocol === 'https:';
}

/**
 * функция проверяет является ли параметр ссылкой на файл и возвращает ее
 * @param file файл или ссылка
 * @returns возвращает ссылку на файл или undefined
 */
export const checkFileLink = (file?: string | File) =>
  typeof file === 'string' ? file : undefined;

/**
 * функция проверяет является ли параметр файлом и возвращает его
 * @param file файл или ссылка
 * @returns возвращает файл или undefined
 */
export const checkSingleFileLink = (file?: string | File) =>
  typeof file === 'string' ? undefined : file;

/**
 * функция проверяет является ли файл пустым если нет, возвращает его
 * @param file файл или ссылка
 * @returns возвращает файл или undefined
 */
export const checkIsFileEmpty = (file?: string | File) => {
  return (typeof file !== 'string' && file?.name && file) || undefined;
};

/**
 * функция меняет хост в ссылке на материал на тот, который в адресной строке
 * @param href ссылка на материал (видео, pdf, youtube) с хоста, где он храниться
 * @returns ссылка с хостом адресной строки
 */
export function changeHostMaterial(href: string) {
  return href?.replace('http', 'https') || '';
}

/**
 * функция создает урл для вебсокета в текущем домене
 * @param path ссылка создаваемого вебсокета (чат, статистика)
 * @returns строка ссылки для открытия вебсокета
 */
export function createWebsocketUrl(path: string) {
  const isSSL = checkIsSSL();
  const wsProtocol = isSSL ? 'wss' : 'ws';
  const nameApi = isSSL
    ? 'api.saynschuman.pp.ua'
    : isDev
    ? 'api.saynschuman.pp.ua' // 'localhost:8000'
    : 'api.saynschuman.pp.ua';

  return `${wsProtocol}://${nameApi}/ws/${path}`;
}

/**
 * функция определяет принадлежность текущего домена группе {@link intellekaDomains}
 * @returns возвращает true, если домен входит в группу {@link intellekaDomains} или false, если нет
 */
export const checkIsIntelleka = (): boolean => intellekaDomains.includes(window.location.hostname);

/** Имя чата */
export type IChatName =
  | 'teacher_chat'
  | 'group_chat'
  | 't_chat'
  | 'g_chat'
  | 'support_chat'
  | 's_chat';

/**
 * Утилита определяет статус чата по имени чата
 * @param chatName имя чата
 * @returns Возвращает число, определяющее статус чата
 */
export const getChatStatusByChatName = (chatName: IChatName) => {
  switch (chatName) {
    case 'teacher_chat':
      return 1;
    case 'group_chat':
      return 2;
    case 't_chat':
      return 1;
    case 'g_chat':
      return 1;
    default:
      return 0;
  }
};

/**
 * утилита генерирует урл чата для открытия вебсокета
 * @param chatName имя чата
 * @param userId айди пользователя
 * @param chatId айди чата
 * @returns строка урла чата
 */
export function createUrlChat(chatName: IChatName, userId: number, chatId?: string) {
  const access = Cookies.get('access');

  const urlChat = chatId
    ? `${chatName}/${userId}/${access}/${chatId}/`
    : `${chatName}/${userId}/${access}/`;

  return urlChat;
}

/**
 * Функция возвращает значение элемента по ключу в localstorage, очищая его от кавычек
 * @param key ключ элемента в localstorage
 * @returns значение элемента
 */
export const getLocalStorageItem = (key: string) => localStorage.getItem(key)?.replaceAll('"', '');

/**
 * функция проверяет наличие ссылки в тексте
 * @param linkText текст
 * @returns true, если в тексте есть ссылка или false, если нет
 */
export const checkingLinkText = (linkText: string) => {
  return (
    !linkText.includes(' ') &&
    !linkText.includes('\n') &&
    ['http', 'https', 'ftp', 'ftps'].find((protocol) => ~linkText.indexOf(protocol))
  );
};

/**
 * функция проверяет является ли текст изображением base64
 * @param text текст
 * @returns true, если в тексте есть ссылка или false, если нет
 */
export const checkIsBase64 = (text: string) => {
  return text.includes('data:image');
};

/**
 * функция проверяет, соответствует ли параметр адресу страницы
 * @param url проверяемая строка
 * @returns true, если есть совпадения строки с адресом текущей страницы | false, если нет
 */
export const hasLocationMatches = (url: string) => {
  const regexp = new RegExp(`${url}`, 'gi');
  return regexp.test(window.location.pathname);
};
