import { useQuery } from 'react-query';
import ClientTemplate from '../templates/ClientTemplate';
import { useCourse } from '../store/useCourse';
import { API } from '../../../api';

const ClientInfo: React.FC = () => {
  const { data: courses } = useQuery('courses', () => API.client.course.getAllCourses());
  const { course } = useCourse();
  const course_description = courses?.find((c) => c.id === course)?.description || '';

  return (
    <ClientTemplate>
      <div dangerouslySetInnerHTML={{ __html: course_description }} className="p-7 children:p-4" />
    </ClientTemplate>
  );
};

export default ClientInfo;
