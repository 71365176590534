import { useTest } from '../store/useTest';
import ClientTaskTemplate from '../templates/ClientTaskTemplate';
import { Alert } from '@mui/material';


const ClientTestUiError: React.FC = () => {
  const { error } = useTest();

  return (
    <ClientTaskTemplate>
      <div className="text-red-600">
        {error && (
          <Alert color="error">
            Что-то пошло не так, приносим извинения. Перезагрузите страницу и повторите попытку еще раз.
          </Alert>
        )}
      </div>
    </ClientTaskTemplate>
  );
};

export default ClientTestUiError;
