import { useMutation } from 'react-query';
import { API } from '../../../api';
import { queryClient } from '../../../hooks/queryClient';
import { useSortMaterials } from '../../../ui/admin/store/useMaterials';
import { getMaterialsKey } from '.';

export const useRemoveMaterial = (courseId: string) => {
  const { setRemoveId } = useSortMaterials();

  return useMutation((materialId: number) => API.admin.material.remove(materialId), {
    onSuccess: (_, materialId) => {
      queryClient.setQueryData(getMaterialsKey({ course: courseId }), () => {
        const oldData = queryClient.getQueryData<{ id: number }[]>(
          getMaterialsKey({ course: courseId }),
        );
        if (oldData) {
          return oldData.filter((material) => material.id !== materialId);
        }
      });
      setRemoveId();
    },
  });
};
