import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API } from '../../../api';
import { queryClient } from '../../../hooks/queryClient';
import { ICourse } from '../../../typings/admin';
import { useCoursesErrors, useCoursesForm } from '../../../ui/admin/store/useCourses';
import { getError } from '../../../utils/api';
import { key } from '.';

export const useAddCourse = () => {
  const { setError } = useCoursesErrors();
  const { toggle } = useCoursesForm();

  return useMutation<ICourse, unknown, ICourse>(
    (data) => API.admin.course.create({ ...data, users: [] }),
    {
      onSuccess: (res) => {
        toggle(false);
        queryClient.setQueryData(key, () => {
          const oldData = queryClient.getQueryData<ICourse[]>(key);
          if (oldData) {
            return [...oldData, res];
          }
        });
      },
      onError: (e) => setError(getError(e as AxiosError)),
    },
  );
};
